<template>
  <div class="time-content-box">
    <div class="time-content-info">
      <a-row :gutter="12" class="time-row">
        <a-col span="16" class="time-col">
          <div class="time-content">
            <a-row :gutter="12" class="time-content-row">
              <a-col span="12" class="time-content-col">
                <div class="time-now">
                  {{ timeNow }}
                </div>
              </a-col>
              <a-col span="12" class="time-content-col">
                <div class="time-other-info">
                  <div class="time-week">{{ week }}</div>
                  <div class="time-date">{{ day }}</div>
                  <div class="time-month">{{ month + 1 }}月</div>
                  <div class="time-lunar">农历{{ lunarDay }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col span="8" class="time-col">
          <div class="ceremony">
            <div>
              <span class="ceremony-name">进厅时间：</span>
              <span>{{ data.to_hall_time }}</span>
            </div>
            <div>
              <span class="ceremony-name">出厅时间：</span>
              <span>{{ data.out_hall_time }}</span>
            </div>
            <div>
              <span class="ceremony-name">感恩仪式：</span>
              <span>{{ data.thankful_time }}</span>
            </div>
            <div>
              <span class="ceremony-name">告别仪式：</span>
              <span>{{ data.leave_time }}</span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { findMobileServiceOrderThankfulLeaveTime } from '@/api/service_order'
export default {
  name: 'Time',
  props: {
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      timeNow: '',
      week: '',
      day: '',
      month: '',
      lunarDay: '',
      data: {},
      loopFetchDataTimer: null
    }
  },
  created() {
    setInterval(this.getNow, 1000)
    this.fetchData()
    this.loopFetchData()
  },
  destroyed() {
    clearInterval(this.loopFetchDataTimer)
  },
  methods: {
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, 60000)
    },

    getNow() {
      const today = new Date()
      this.timeNow = this.$moment(today).format('HH:mm')
      this.week = this.setWeek(today.getDay())
      this.day = today.getDate()
      this.month = today.getMonth()
      this.lunarDay = this.$lunar(today.getFullYear(), today.getMonth() + 1, today.getDate()).dateStr
    },

    fetchData() {
      findMobileServiceOrderThankfulLeaveTime(this.serviceOrderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    setWeek(day) {
      switch (day) {
        case 0:
          return '星期日'
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.time-content-box {
  height: 100%;

  .time-content-info {
    .time-col {
      height: 100%;
    }
  }

  .time-content {
    padding: 10px;
    height: 180px;
    border-radius: 6px;
    background-color: #0d2f25ff;

    .time-content-row {
      height: 100%;

      .time-content-col {
        height: 100%;

        .time-now {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          height: 100%;
          font-size: 64px;
          border-radius: 6px;
          background-color: #2a4c39ff;
        }

        .time-other-info {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          height: 100%;
          font-size: 24px;

          .time-week {
            color: #6b7975;
          }

          .time-date {
            font-size: 40px;
          }

          .time-month {
            color: #6b7975;
          }

          .time-lunar {
            color: #ffd794ff;
          }
        }
      }
    }
  }

  .ceremony {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 180px;
    font-size: 20px;

    .ceremony-name {
      color: #ffd794ff;
    }
  }
}
</style>
