<template>
  <div class="box">
    <div v-if="data.service_order_id > 0">
      <a-row :gutter="24" class="hall-title">
        <a-col span="12">
          <div class="hall-content">
            <span>厅房名称</span>
            <span class="hall-value">{{ data.name }}</span>
          </div>
        </a-col>
        <a-col span="12">
          <div class="hall-content">
            <span>逝者姓名</span>
            <a-space>
              <span>{{ serviceOrderDeath && serviceOrderDeath.name }}</span>
              <span class="hall-link custom-cursor-pointer" @click="toDeath">简介 ></span>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <a-row>
        <a-col span="6">
          <div class="node-box" @scroll="resetLoopFetchData" @click="resetLoopFetchData">
            <div class="node-day" v-for="(item, index) in nodes" :key="item.day">
              <div v-show="(index + 1) == currentDay">
                <div class="day">
                  <a-icon type="left" @click="toLastDay" />
                  <div class="day-content">
                    <span>{{ item.name }}</span>
                    <span>{{ item.date }}</span>
                  </div>
                  <a-icon type="right" @click="toNextDay" />
                </div>

                <div
                  v-for="(node, index) in item.processes"
                  :key="index"
                  class="node"
                  :style="{backgroundColor: node.is_next ? '#ffd794ff' : '', color: node.is_next ? '#323232FF' : ''}"
                >
                  <div class="node-name">{{ node.name }}</div>
                  <div>{{ node.is_finished ? '已完成' : '' }}</div>
                  <div class="node-remark">{{ node.remark }}</div>
                </div>
              </div>
            </div>

          </div>
        </a-col>
        <a-col span="18">
          <!-- 时间 -->
          <a-row :gutter="[24,24]">
            <a-col span="24">
              <div class="custom-card-box" style="border-radius: 10px;">
                <time-info :service-order-id="data.service_order_id" />
              </div>
            </a-col>
          </a-row>

          <a-row :gutter="[24,24]">
            <a-col span="12">
              <!-- 订购-->
              <div class="custom-card-box sub-box">
                <img src="/temp/mobile_sub.png" alt="logo">
                <a-button type="primary" @click="toProduct" class="btn-twinkle">
                  线上订购<br>花篮花圈殡葬用品
                </a-button>
              </div>
            </a-col>

            <a-col span="12">
              <!-- 订购二维码-->
              <div class="custom-card-box sub-qr-box">
                <div class="wechat-sub" v-if="qrCode">
                  <div class="wechat-content">
                    <div class="wechat-sub-qr">
                      <qrcode-vue
                        v-if="qrCode"
                        :value="qrCode"
                        :size="100"
                        level="H"
                      />
                    </div>
                    <span>微信扫码关注</span>
                    <span>领五元代金券</span>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>

          <a-row :gutter="[24,24]">
            <a-col span="24">
              <div class="custom-card-box" style="display: flex;justify-content:space-around">
                <div class="user-info">
                  <div class="user-content">
                    <div>
                      <span class="user-label">白班电话：</span>
                      <span>{{ '18106295239 (08:30 ~ 17:00)' }}</span>
                    </div>
                    <div>
                      <span class="user-label">夜班电话：</span>
                      <span>{{ '18006295444 (17:00 ~ 次日08:30)' }}</span>
                    </div>
                  </div>
                </div>
                <div class="custom-card-box hall-qr-box">
                  <div class="wechat-sub">
                    <div class="wechat-content">
                      <div class="wechat-sub-qr">
                        <qrcode-vue
                          :value="hallQrCode"
                          :size="100"
                          level="H"
                        />
                      </div>
                      <span>厅房二维码</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>

          <a-row :gutter="[24,24]">
            <a-col span="24">
              <div class="custom-card-box">
                <tip :service-order-id="data.service_order_id" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <no-data :data="data" v-else />

    <valid-phone-number
      v-if="isShowValidPhoneNumberModal"
      :visible.sync="isShowValidPhoneNumberModal"
      :service-order-id="data.service_order_id"
      @completed="handleToProduct"
      :master-phone="data.master_phone"
    />
  </div>
</template>

<script>
import { findServiceOrderDeath } from '@/api/service_order_death'
import { findMobileHallNodes } from '@/api/hall'
import { findSubscribeOrderQrCodeUrl, findMobileServiceOrderUser } from '@/api/service_order'
import NoData from '@/views/halls/show/mobile/in_use/NoData'
import TimeInfo from '@/views/halls/show/mobile/in_use/Time'
import Tip from '@/views/halls/show/mobile/in_use/Tip'
import SpeakTts from 'speak-tts'
export default {
  name: 'MobileShowHallInUseInfo',
  components: {
    NoData,
    TimeInfo,
    Tip,
    QrcodeVue: () => import('qrcode.vue'),
    ValidPhoneNumber: () => import('@/views/halls/show/mobile/in_use/ValidPhoneNumber')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      serviceOrderDeath: undefined,
      nodes: [],
      currentDay: 1,
      defaultDay: 1,
      qrCode: '',
      user: {},
      isShowValidPhoneNumberModal: false,
      loopFetchDataTimer: null,
      initLoopTimeout: 60000,
      loopTimeout: 60000,
      hallQrCode: '',
      tts: null
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
    this.loopFetchData()
  },
  destroyed() {
    this.$socket.unsubscribe('hall_flower_basket_messages', this.unsubscribe)
    clearInterval(this.loopFetchDataTimer)
  },
  mounted() {
    this.tts = new SpeakTts()
    this.tts.setLanguage('zh-CN')
    this.tts.init({
      rate: 1
    }).then(() => {})
    this.$socket.subscribe('hall_flower_basket_messages', this.speak)
  },
  methods: {
    speak(res) {
      console.log(res)
      console.log(this.id)
      if (parseInt(res.hall_id) === this.id) {
        this.tts.setLanguage('zh-CN')
        // this.tts.setVoice('婷婷')
        // this.tts.setVoice('Microsoft Kangkang - Chinese (Simplified, PRC)')
        this.tts.speak({
          text: res.content,
          queue: true,
          listeners: {
            onend: () => {
              console.log('finished speaking')
            }
          }
        })
      }
    },

    unsubscribe() {
      console.log('unsubscribe')
    },

    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, this.loopTimeout)
    },

    fetchData() {
      this.fetchNodes()
      this.fetchSearchOrderDeath()
      this.fetchQrCode()
      this.fetchUser()
    },

    fetchSearchOrderDeath() {
      findServiceOrderDeath({ hall_id: this.id }).then((res) => {
        if (res.code === 0) {
          this.serviceOrderDeath = res.data
          this.fetchHallQrCode()
        }
      })
    },

    fetchNodes() {
      if (this.loopTimeout !== this.initLoopTimeout) {
        this.loopTimeout = this.initLoopTimeout
        this.currentDay = this.defaultDay
      }
      findMobileHallNodes(this.id).then((res) => {
        if (res.code === 0) {
          this.nodes = res.data
          if (this.nodes) {
            for (let i = 0; i < this.nodes.length; i++) {
              if (this.nodes[i].is_today) {
                this.currentDay = i + 1
                this.defaultDay = i + 1
              }
            }
          }
        }
      })
    },

    fetchQrCode() {
      findSubscribeOrderQrCodeUrl(this.data.service_order_id).then((res) => {
        if (res.code === 0) {
          this.qrCode = res.data.qr_code_url
        }
      })
    },

    fetchUser() {
      findMobileServiceOrderUser(this.data.service_order_id).then((res) => {
        if (res.code === 0) {
          this.user = res.data
        }
      })
    },

    toLastDay() {
      if (this.currentDay > 1) {
        this.currentDay -= 1
      }
    },

    toNextDay() {
      if (this.currentDay < 3) {
        this.currentDay += 1
      }
    },

    resetLoopFetchData() {
      this.loopTimeout = 5 * this.initLoopTimeout
      clearInterval(this.loopFetchDataTimer)
      this.loopFetchData()
    },

    toProduct() {
      if (this.data.is_can_subscribe) {
        this.isShowValidPhoneNumberModal = true
      } else {
        this.$warning({
          title: '当前订单状态不能增加殡仪服务新增项订单',
          content: ''
        })
      }
    },

    handleToProduct() {
      this.$router.push({ name: 'sub_products', query: { hall_id: this.id, service_order_id: this.data.service_order_id }})
    },

    toDeath() {
      this.$router.push({ name: 'mobile_death_info', query: { hall_id: this.id }})
    },

    fetchHallQrCode() {
      this.hallQrCode = 'hall_id=' + this.id +
          '&service_order_death_id=' + this.serviceOrderDeath.service_order_death_id +
          '&hall_name=' + this.data.name + '&death_name=' + this.serviceOrderDeath.name
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  height: 100%;

  .hall-title {
    margin-bottom: 20px;

    .hall-content {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      font-size: 25px;
      border-radius: 8px;
      background: linear-gradient(to top right, #2a4c39ed, #2a4c3966);
    }

    .hall-value {
      font-weight: bold;
    }

    .hall-link {
      margin-left: 20px;
      color: #ffd794ff;
    }
  }

  .node-box {
    overflow: auto;
    height: 1650px;

    .node-day {
      .day {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        width: 200px;
        font-size: 24px;
        border-radius: 10px;
        background-color: #2d63ab;
        align-content: center;
      }

      .day-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }

    .node {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0;
      padding: 20px;
      width: 200px;
      min-height: 150px;
      font-size: 20px;
      border-radius: 10px;
      background-color: #37543e;
      flex-direction: column;

      .node-name {
        font-size: 24px;
      }

      .node-remark {
        white-space: pre-wrap;
      }
    }
  }

  .sub-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 240px;
    border-radius: 10px;
    flex-direction: column;

    .btn-twinkle {
      height: 40px;
      border: none;
      color: #fff;
      animation: twinkle 1s alternate infinite;
    }

    @keyframes twinkle {
      from { background: #16e2eb; }
      to { background: #2d63ab; }
    }

    img {
      width: 120px;
    }
  }

  .sub-qr-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;

    .wechat-sub {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 10px;
      flex-direction: column;
      height: 100%;
      align-content: center;

      .wechat-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-content: center;

        .wechat-sub-qr {
          margin-bottom: 10px;
          padding: 10px;
          background-color: white;
        }
      }
    }
  }

  .hall-qr-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;

    .wechat-sub {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 10px;
      flex-direction: column;
      height: 100%;
      align-content: center;

      .wechat-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-content: center;

        .wechat-sub-qr {
          margin-bottom: 10px;
          padding: 10px;
          background-color: white;
        }
      }
    }
  }

  .user-info {
    display: flex;
    font-size: 18px;

    img {
      margin-bottom: 10px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .user-content {
      display: flex;
      justify-content: space-evenly;
      margin-left: 20px;
      flex-direction: column;
      font-size: 20px;
    }

    .user-label {
      width: 20px;
      color: #ffd794ff;
    }
  }
}
</style>

