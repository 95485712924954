<template>
  <div @click="resetLoopFetchData">
    <div>
      <div v-for="(notice, index) in data" :key="index">
        <div v-if="index === currentIndex" class="notice-title">
          <a-icon type="left" @click="toLast(index)" style="font-size: 18px;font-weight: bold;" />
          <span>{{ notice.name }}</span>
          <a-icon type="right" @click="toNext(index)" style="font-size: 18px;font-weight: bold;" />
        </div>

        <div class="content-box" v-if="index === currentIndex">
          <div
            v-if="notice.content && notice.slug != 'wreath'"
            class="content"
            v-html="notice.content"
          />
          <div v-else-if="notice.content && notice.slug === 'wreath'" class="content">
            <div v-for="(giver,id) in notice.givers" :key="id" style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 10px">
              <span>{{ giver.name }}</span>
              <span>{{ giver.status === 'delivered' ? '已送达' : '' }}</span>
              <a-button type="primary" @click.stop="wreathArrived(giver.order_id)" v-if="giver.status !== 'delivered'">送达</a-button>
            </div>
          </div>
          <div v-else class="no-data">
            <img src="/temp/mobile_no_data.png" alt="logo">
            <span> 暂无数据 </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findMobileServiceOrderNotices } from '@/api/service_order'
import { wreathArrived } from '@/api/order'
export default {
  name: 'Tip',
  props: {
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loopFetchDataTimer: null,
      initLoopTimeout: 60000,
      loopTimeout: 60000,
      currentIndex: 0,
      autoPlayTimer: null,
      initAutoPlayTime: 30000,
      autoPlayTime: 30000
    }
  },
  created() {
    this.fetchData()
    this.loopFetchData()
    this.autoPlayTimer = setInterval(this.autoPlay, this.initAutoPlayTime)
  },
  destroyed() {
    clearInterval(this.loopFetchDataTimer)
    clearInterval(this.autoPlayTimer)
  },
  methods: {
    wreathArrived(orderId) {
      wreathArrived(orderId).then((res) => {
        if (res.code === 0) {
          this.fetchData()
        }
      })
    },
    reloadData() {
      this.data = []
      findMobileServiceOrderNotices(this.serviceOrderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, this.loopTimeout)
    },

    fetchData() {
      if (this.loopTimeout !== this.initLoopTimeout) {
        this.loopTimeout = this.initLoopTimeout
        this.currentIndex = 0
      }
      findMobileServiceOrderNotices(this.serviceOrderId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
    },

    toLast(index) {
      if (index > 0) {
        this.currentIndex = index - 1
      }
    },

    toNext(index) {
      if (index < this.data.length - 1) {
        this.currentIndex = index + 1
      }
    },
    autoPlay() {
      if (this.autoPlayTime !== this.initAutoPlayTime) {
        this.autoPlayTime = this.initLoopTimeout
        clearInterval(this.autoPlayTimer)
        this.autoPlayTimer = setInterval(this.autoPlay, this.initAutoPlayTime)
      }
      if (this.data.length > 0) {
        if (this.currentIndex >= this.data.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex = this.currentIndex + 1
        }
      }
    },

    resetLoopFetchData() {
      this.loopTimeout = 5 * this.initLoopTimeout
      clearInterval(this.loopFetchDataTimer)
      this.loopFetchData()

      this.autoPlayTime = 10 * this.initAutoPlayTime
      clearInterval(this.autoPlayTimer)
      this.autoPlayTimer = setInterval(this.autoPlay, this.autoPlayTime)
    }
  }
}
</script>

<style lang="less" scoped>
.content-box {
  overflow: auto;
  height: 800px;

  .content {
    font-size: 24px;
  }
}

.notice-title {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;

  span {
    font-size: 24px;
    color: #ffd794ff;
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  img {
    width: 100px;
  }

  span {
    margin-top: 10px;
    font-size: 20px;
  }
}
</style>

