<template>
  <div class="box">
    <a-row>
      <a-col span="24">
        <div class="hall-idle-content">
          <div class="hall-qr-box">
            <div class="wechat-sub">
              <div class="wechat-content">
                <div class="wechat-sub-qr">
                  <qrcode-vue
                    :value="hallQrCode"
                    :size="200"
                    level="H"
                  />
                </div>
                <span>厅房二维码</span>
                <span style="font-size: 30px">暂无遗体进厅，未获取订单数据</span>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
<!--    <img src="/temp/mobile_no_data.png" alt="logo">-->
<!--    <span> 暂无遗体进厅，未获取订单数据</span>-->
  </div>
</template>

<script>
export default {
  name: 'MobileShowHallInUseNoData',
  data() {
    return {
      hallQrCode: ''
    }
  },
  components: {
    QrcodeVue: () => import('qrcode.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchHallQrCode()
  },
  methods: {
    fetchHallQrCode() {
      this.hallQrCode = 'hall_id=' + this.id +
          '&service_order_death_id=0&hall_name=' + this.data.name +
          '&death_name='
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  height: 100%;

  .hall-title {
    margin-bottom: 20px;

    .hall-content {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      font-size: 25px;
      border-radius: 8px;
      background: linear-gradient(to top right, #2a4c39ed, #2a4c3966);
    }

    .hall-value {
      font-weight: bold;
    }

    .hall-link {
      margin-left: 20px;
      color: #ffd794ff;
    }
  }

  .node-box {
    overflow: auto;
    height: 1650px;

    .node-day {
      .day {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        width: 200px;
        font-size: 24px;
        border-radius: 10px;
        background-color: #2d63ab;
        align-content: center;
      }

      .day-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }

    .node {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0;
      padding: 20px;
      width: 200px;
      min-height: 150px;
      font-size: 20px;
      border-radius: 10px;
      background-color: #37543e;
      flex-direction: column;

      .node-name {
        font-size: 24px;
      }

      .node-remark {
        white-space: pre-wrap;
      }
    }
  }

  .sub-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 240px;
    border-radius: 10px;
    flex-direction: column;

    .btn-twinkle {
      height: 40px;
      border: none;
      color: #fff;
      animation: twinkle 1s alternate infinite;
    }

    @keyframes twinkle {
      from { background: #16e2eb; }
      to { background: #2d63ab; }
    }

    img {
      width: 120px;
    }
  }

  .sub-qr-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;

    .wechat-sub {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 10px;
      flex-direction: column;
      height: 100%;
      align-content: center;

      .wechat-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-content: center;

        .wechat-sub-qr {
          margin-bottom: 10px;
          padding: 10px;
          background-color: white;
        }
      }
    }
  }
  .hall-idle-content {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    font-size: 25px;
    border-radius: 8px;
    background: linear-gradient(to top right, #2a4c39ed, #2a4c3966);
    height: 1650px;
    align-items: center;
    .hall-qr-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;

      .wechat-sub {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 10px;
        flex-direction: column;
        height: 100%;
        align-content: center;

        .wechat-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          align-content: center;

          .wechat-sub-qr {
            margin-bottom: 10px;
            padding: 10px;
            background-color: white;
          }
        }
      }
    }
  }

  .user-info {
    display: flex;
    font-size: 18px;

    img {
      margin-bottom: 10px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .user-content {
      display: flex;
      justify-content: space-evenly;
      margin-left: 20px;
      flex-direction: column;
    }

    .user-label {
      width: 20px;
      color: #ffd794ff;
    }
  }
}
</style>

